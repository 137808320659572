<template>
    <keep-alive>
        <div id="properties">
            <PageTitle :title="title" />
            <div v-if="!$store.getters.getLoaded">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div class="filters"  v-if="$store.getters.getLoaded">
                <p>Filter Properties:</p>
                <multiselect @input="updateFilterOption" :value="currentFilterOption" placeholder="Select status" :showLabels="false" :options="options" track-by="label" label="label" :searchable="false" :allow-empty="false">
                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.label }}</strong></template>
                </multiselect>
            </div>
            <div class="properties-container"  v-if="$store.getters.getLoaded">
                <div class="property" v-for="(property, i) in filteredProperties" :key="i">
                    <router-link :to="`/property/${property.Slug}`">
                        <img :src="property.DisplayImage ? url+property.DisplayImage.url : '/images/placeholder.png'" :alt="property.Address" >
                    </router-link>
                    <div class="info">
                        <div class="address">
                            <router-link :to="`/property/${property.Slug}`">
                                <p>
                                    {{property.Address}}, 
                                    {{property.Area}}
                                </p>
                            </router-link>
                            <router-link :to="`/property/${property.Slug}`">
                                <p>
                                    {{ property.Price | priceFormat}} <span v-if="property.Lease"> / mo.</span>
                                </p>
                            </router-link>
                        </div>
                        <div class="tag" :class="property.Tag">
                            {{property.Tag | startCase}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </keep-alive>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Multiselect from 'vue-multiselect'
import Spinner from 'vue-simple-spinner'
import { mapState, mapActions } from 'vuex';

export default {
    name: "Properties",
    components: {
        PageTitle,
        Spinner,
        Multiselect
    },
    data(){
        return{
            title: "Properties",
            url: 'https://strapi.uptowncreative.io',
            options: [
                {label: 'ALL', value: 'all'},
                {label: 'Available', value: 'available'},
                {label: 'Sold', value: 'sold'},
            ],
            currentOption:  {label: 'ALL', value: 'all'},
            loading: this.$store.getters.getLoaded,
        }
    },
    computed: {
        filteredProperties:function(){
            const unfilteredProperties = this.$store.getters.properties;
            if (this.$store.getters.currentFilterOption.value === 'all'){
                return unfilteredProperties.sort(
                    (a, b) => { 
                        if (a.Tag === 'available' && b.Tag !== 'available'){
                            return -1;
                        } else if (a.Tab !== 'available' && b.Tag === 'available'){
                            return 1;
                        } else {
                            return b.Price - a.Price;
                        }
                    })
            } else if (this.$store.getters.currentFilterOption.value === 'sold'){
                return unfilteredProperties.filter((item) => {
                    return item.Tag === 'sold' || item.Tag === 'past';
                })
            } else {
                return unfilteredProperties.filter((item) => {
                    return item.Tag === this.$store.getters.currentFilterOption.value;
                })
            }
        },
        ...mapState(['currentFilterOption'])
    },
    metaInfo: {
        title: 'Properties',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description', 
            content: "Paul Salazar"
        }]
    },
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        ...mapActions(['updateFilterOption'])
    },
    created() {
        if (this.$route.params.category){
            if (this.$route.params.category === 'sold') {
                this.$store.dispatch('updateFilterOption',this.options[2]);
            }
        }
    },
    mounted:function(){
        if(!this.$store.getters.loaded){
            this.$store.dispatch('updateProperties');
            this.$store.dispatch('propertiesLoaded');
        } else if(this.$store.getters.checkExpiryDate){
            this.$store.dispatch('updateProperties');
        }
    },
    watch: {
        $route() {
            if (this.$route.params.category){
                if (this.$route.params.category === 'sold') {
                    this.$store.dispatch('updateFilterOption',this.options[2]);
                }
            } else {
                this.$store.dispatch('updateFilterOption',this.options[1]);
            }
        }
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.properties-container{
    margin: 0 auto;
    max-width: 1000px;
}
.property{
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-top: 4rem;
    &:last-child{
        border-bottom: none;
    }
    a{
        display: block;
    }
    img{
        width: 100%;
        max-height: 470px;
        display: block;
        object-fit: cover;
    }
    .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .address{
        display: flex;
        flex-direction: column;
        color: #fff;
        h3{
            font-weight: 500;
            margin: 0;
            opacity: 0.5;
            text-transform: uppercase;
            &:hover{
                opacity: 1;
            }
        }
        p{
            text-transform: uppercase;
            font-weight: 100;
            &:hover{
                opacity: 1;
            }
        }
    }
    .tag{
        margin: 0;
        color: #fff;
    }
    .tag.sold, .tag.past{
        color: #000 !important;
        background: #fff;
    }
}

@media (max-width: 1000px) { 
    .properties-container{
        margin: 2rem 4rem;
    }
}
@media (max-width: $mobile) { 
    .filters{
        padding: 2rem;
    }
    .properties-container{
        margin: 2rem 0;
    }
    .property{
        border-bottom: unset;
        padding-top: 2rem;
        .info{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 2rem;
            margin-right: 2rem;
            padding-bottom: 4rem;
            border-bottom: 1px solid rgba(255,255,255,0.3);
            p{
                margin-top: 0.5rem;
            }
        }
        &:last-child{
            .info{
                border-bottom: none;
                padding-bottom: 2rem;
            }
        }
    }
}
</style>
<style lang="scss">
.filters{
    display: flex;
    justify-content: flex-end;
    padding: 2rem 4rem;
    background: #1a1a1a;
    align-items: center;
    p{
        margin: 0;
        margin-right: 30px;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: 100;
        opacity: 0.25;
    }
}

.multiselect{
  &__tags{
    background: #1a1a1a !important;
  }
  &__single{
    color: #fff;
    background: #1a1a1a !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 2px;
  }
}

.multiselect{
    background: #1a1a1a;
    width: 200px
}
.multiselect__tags{
    background: #1a1a1a;
    border-radius: 1px;
    border: 1px solid rgba(255,255,255,0.3);
}
.multiselect__option{
    letter-spacing: 2px;
    text-transform: uppercase;
}
.multiselect__option--selected.multiselect__option--highlight{
    background: #1a1a1a !important;
}
.multiselect__option--highlight{
    background:#1a1a1a !important;
}
.multiselect__current, .multiselect__select{
    line-height: unset;
}
</style>
